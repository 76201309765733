.App {
  text-align: center;
}

.appcontentContainer {
  padding-top: 100px;
}

.header {
  font-size: 50px;
  color: blue;
}