.generalHeroContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50vh;
  background-size: cover;
  background-position: center 60%;
  justify-content: center;
}

.bannerText {
  position: relative;
  max-width: 600px;
  padding: 8px 30px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.40); // Dim transparent black background
  border-radius: 10px;

  h1 {
    font-size: 45px;
    font-weight: bold;
    color: #fff; // Optional: if you want to set a text color
  }
}