.columnContainer {
  width: 100%;
  display: flex;
  height: 700px;

  @media (max-width: 850px) {
    flex-direction: column;
    height: auto;
  }
}

.productsContainer {
  max-width: 1400px;
  width: 100%;
  display: flex;
  margin: auto;
}

.leftCol, .rightCol {
  width: 100%;
}

.darkColumn {
  background-color: #0B0B0B;
}

.lightColumn {
  background-color: #24343F;
}

.imageColumn {
  background-color: white;
  background-position: top center;
  background-size: cover;
  min-height: 400px;

  @media (max-width: 850px) {
    display: none;
    height: 0px;
  }
}

.textContainer {
  text-align: left;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  h2 {
    font-size: 28px;
    padding-bottom: 20px;
    color: white;
    font-weight: bold;
  }

  p {
    color: #E7E6D8;
  }

  @media (max-width: 850px) {
    background-size: cover;
    background-position: center;
  }
}

.textContainer::before {
  filter: brightness(40%);
}