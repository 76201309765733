/* HeroBanner.module.scss */
.container {
  width: 100%;
  display: flex;
  margin: auto;
}

.heroBanner {
  margin: auto;
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  background-color: black;
}

.heroBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./../../assets/e115_main_hero_2.jpg');
  background-size: cover;
  background-position: center 55%;
  filter: brightness(0.63); /* Add overlay effect if needed */
}

.heroContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  z-index: 1;
}

.heroLogo img {
  max-width: 420px;
  padding: 0px 40px;
  width: 100%;
  height: auto;
  margin: auto;

  @media screen and (max-width: 600px) {
    width: 65%;
  }
}


@media (max-width: 750px) {
  .heroBanner {
    height: 70vh;
  }
}

@media (max-width: 550px) {
  .heroBanner {
    height: 50vh;
  }
}
