.gifContainer {
  position: relative; /* Add this for positioning the pseudo-element */
  width: 100%;
  height: 550px;
  margin: auto;
  padding: 50px;
  display: flex;
  justify-content: center;
}

.gifContainer::before {
  content: ""; /* Pseudo-element content */
  background: url('../../../assets/shrom.gif');
  background-size: cover;
  background-position: top;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  filter: brightness(0.67) saturate(0.7); /* Add overlay effect */
  z-index: -1; /* Place behind other content */
}

.gifContainer__textContent {
  position: relative; /* Add this for z-index to work */
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1; /* Ensure the text is above the overlay */

  h2 {
    padding: 20px 0px;
    margin: 0px;
    font-size: 50px;
    line-height: 60px;
    font-weight: bold;

    @media (max-width: 600px) {
      font-size: 40px;
    }
  }
}

.ctaBtn {
  border: 3px solid white !important;
  padding: 20px 25px !important;

  &:hover {
    border-color: greenyellow !important;
    color: greenyellow;
  }
}

.awardsRow {
  position: relative;
  background-image: url('./../../../assets/stars.jpg');
  background-size: cover;
  background-position: center 25%;
}

.awardsRow::before {
  position: absolute;
  filter: brightness(0.30);
}

.awardsContent {
  padding: 50px !important;
}

.fireIcon {
  font-size: 70px;
  color: #FF9C06;
}

.awardIconContainer {
  display: flex;

  img {
    max-width: 100% !important;
    width: 160px;
    margin: 20px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
}