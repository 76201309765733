.footerContainer {
  color: white;
  padding: 50px 20px;
  max-width: 2000px;
  width: auto;
  text-align: left;
  border-bottom: 2px solid #212121;
  margin: 40px;
  display: flex;

  @media (max-width: 999px) {
    margin: 20px;
  }
}

.logoContainer {
  max-width: 100px;
}

.leftCol, .rightCol {
  display: flex;
}

.leftCol {
  padding-right: 20px;
  flex-shrink: 1;
}

.rightCol {
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-end;
}

.listContainer {
  display: flex;
  padding: 0px 40px;
  
  .listItem {
    padding: 0px 20px;
    font-weight: lighter;

    ul {
      list-style: none;

      li:hover {
        color: greenyellow;
      }
    }
  }
}

.listHeader {
  font-weight: bold;
}

@media (max-width: 650px) {
  .footerContainer {
    flex-direction: column;
    margin-top: 0px;
    padding: 50px 0px;
  }

  .rightCol {
    align-items: flex-start;
    padding: 20px 0px;
  }
}

@media (max-width: 700px) {
  .leftCol {
    flex-direction: column;
  }

  .listContainer {
    padding: 20px 0px;
  }

  .logoContainer {
    padding: 0px 20px;
    max-width: 110px;
  }
  
}

.copyrightContainer {
  padding-bottom: 40px;
  width: auto;
  // height: 100px;
  color: white;
  font-weight: lighter;
}